@import url('https://fonts.googleapis.com/css2?family=Tomorrow:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Smooch+Sans&display=swap');

.App {
  display: flex;
  flex-direction: column;
  background-color: black;
  text-align: center;
  scroll-behavior: smooth;
}

.nav{ /* div padre*/
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: black;
  color: white;
  /* position: fixed;
  z-index: 1; */
  align-items: center;
  justify-content: center;
  font-family: 'Tomorrow', sans-serif;
}

.glitch {
  /* font-size: 3rem; */
  font-weight: 700;
  /* text-transform: uppercase; */
  position: relative;

  text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
    -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
    0.025em 0.05em 0 rgba(0, 0, 255, 0.75);

  animation: glitch 500ms infinite;
}

.glitch span {
  position: absolute;
  top: 0;
  left: 0;
}

.glitch span:first-child {
  animation: glitch 650ms infinite;
  clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
  transform: translate(-0.025em, -0.0125em);
  /* color: green; */
  opacity: 0.8;
}

.glitch span:last-child {
  animation: glitch 375ms infinite;
  clip-path: polygon(0 80%, 100% 20%, 100% 100%, 0 100%);
  transform: translate(0.0125em, 0.025em);
  /* color: red; */
  opacity: 0.8;
}

@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
  }
  14% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
  }
  15% {
    text-shadow: -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
      0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
      -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  49% {
    text-shadow: -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
      0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
      -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  50% {
    text-shadow: 0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
      0.05em 0 0 rgba(0, 255, 0, 0.75), 0 -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  99% {
    text-shadow: 0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
      0.05em 0 0 rgba(0, 255, 0, 0.75), 0 -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  100% {
    text-shadow: -0.025em 0 0 rgba(255, 0, 0, 0.75),
      -0.025em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}

.linkHome{
  text-decoration: none;
}

.divSwag{
  font-family: 'Tomorrow', sans-serif;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
  background-color: black;
  border-top: 1px white groove ;
  border-bottom: 1px white groove ;
  margin-bottom: 1vw;
}

.textSwag{
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transform:translateX(100%);
  animation: cssmarquee 20s linear infinite;
  
}

@keyframes cssmarquee {
0% {
transform: translateX(90vw);
}
100% {
transform: translateX(-90vw);
}
}

/* Item.js  */

.webDiv{
  margin: 30px;
  width: auto;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  box-shadow: -1px 16px 35px -1px rgba(0,0,0,0.26);
  background-color: #fafafa;
  animation-duration: 0.5s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
  cursor: pointer;
}

/* .webDiv:hover{
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
} */

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.img{
  width: 20vw;
  height: 30vh;
  border-radius: 8px;
  object-fit: fill;
}

.toWeb{
  text-decoration: none;
  color: black;
}

.name{
  margin-top: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  /* padding-top: 2vh; */
  font-family: 'Smooch Sans', sans-serif;
  font-size: 36px;
  font-weight: 500;
}

.explore{
  width: 20vw;
  border-radius: 5px;
  margin-top: 2vh;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Smooch Sans', sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 2px;
}

/* ItemListContainer.js */

.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
}

.subcontainer{
  margin-bottom: 10vh;
}

.Pfilter{
  color: white;
  background-color:white;
  border-color: black;
  border-radius: 5px;
  width: auto;
  height: auto;
  margin-right: 2vw;
}
.Pfilter:hover{
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
}

.Sfilter{
  color: white;
  background-color: white;
  border-color: black;
  border-radius: 5px;
  width: auto;
  height: auto;
  margin-right: 2vw;
}
.Sfilter:hover{
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
}

.Rfilter{
  color: white;
  background-color: white;
  border-color: black;
  border-radius: 5px;
  width: auto;
  height: auto;
  margin-right: 2vw;
}
.Rfilter:hover{
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
}

.linkPfilter{
  color: black;
  text-decoration: none;
  font-weight: 800;
}

.linkRfilter{
  color: black;
  text-decoration: none;
  font-weight: 800;
}

.linkSfilter{
  color: black;
  text-decoration: none;
  font-weight: 800;
}

h2{
  font-size: 1rem;
  color: white;
  font-family: 'Tomorrow', sans-serif;
}

.tobi{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 5vw;
}